/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

	// Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
		  if($(".validation_error").size() > 0) {
			  $('html, body').animate({
				  scrollTop: $(".validation_error").first().offset().top
			  }, 500);
		  }
		  if(jQuery(".datepicker").size() > 0){
		  	jQuery(".datepicker").datepicker( "option", "minDate", 0 );
		  }
		  equalheight = function(container){

		      var currentTallest = 0,
			      currentRowStart = 0,
			      rowDivs = [],
			      $el,
			      topPosition = 0;
		      $(container).each(function() {

			      $el = $(this);
			      $($el).height('auto');
			      topPostion = $el.position().top;

			      if (currentRowStart !== topPostion) {
				      for (currentDiv = 0 ; currentDiv < rowDivs.length ; currentDiv++) {
					      rowDivs[currentDiv].height(currentTallest);
				      }
				      rowDivs.length = 0; // empty the array
				      currentRowStart = topPostion;
				      currentTallest = $el.height();
				      rowDivs.push($el);
			      } else {
				      rowDivs.push($el);
				      currentTallest = (currentTallest < $el.height()) ? ($el.height()) : (currentTallest);
			      }
			      for (currentDiv = 0 ; currentDiv < rowDivs.length ; currentDiv++) {
				      rowDivs[currentDiv].height(currentTallest);
			      }
		      });
	      };

	      $(window).load(function() {
		      equalheight('.top-item-text');
		      equalheight('.list-box .one-box');
		      equalheight('.items');
		      equalheight('.list-cafe-one ');
			  equalheight('.grid figure');
			  equalheight('.blue-parts');

		      var height_master = $('.equalheight_master').height();
		      $('.equalheight_slave').height(height_master + 15);

		      $('.close-btn').click(function(){
			      $(this).parent().removeClass('active slideInLeft animated');
		      });

		      $('.box-group-one .icon').click(function(){
			      $(this).parent().parent().find('.hover-box-group').toggleClass('active slideInLeft animated');
		      });

		      $('footer li a').click(function(){
			      $(this).parent().addClass('active');
		      });

		      $('.close-menu').click(function(){
			      $(this).parent().parent().removeClass('active');
		      });

		      var view_port_height = $(window).height();
		      $('.full_height_banner').height(view_port_height - 400);

		      $('.down-arrow').click(function() {
			      var moveTo = $(this).attr('href');
			      $('body').animate({
				      scrollTop: $(moveTo).offset().top
			      }, 1000);
		      });

		      // Menu

		      $(".btn-menu").click(function(){
			      $(".full_screen_menu").fadeToggle();
			      $(".icon").fadeToggle();
			      var btn_menu = $( '.btn-menu' );
			      if( btn_menu.hasClass( 'close-icon' ) ) {
				      $( btn_menu ).removeClass("close-icon");
			      } else {
				      $( btn_menu ).addClass("close-icon");
			      }
		      });

		      jQuery('form.search-form').on('submit', function(e){
			      if( $(this).find('.search-field').val() === "" ) {
				      e.preventDefault();
			      }
		      });

		      $( ".search-form" ).hover(
			      function() {
				      $( this ).addClass( "hover" );
			      }, function() {
				      $( this ).removeClass( "hover" );
			      }
		      );
	      });

	      $(window).resize(function(){
		      equalheight('.top-item-text');
		      equalheight('.items');
		      equalheight('.list-cafe-one ');
		      equalheight('.list-box .one-box');
		      equalheight('.grid figure');
		      equalheight('.grid figure');
		      equalheight('.blue-parts');

		      var height_master = $('.equalheight_master').height();
		      $('.equalheight_slave').height(height_master);
	      });
      },
      finalize: function() {

      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
	  // News page
	  'blog': {
		  init: function() {


		  },
		  finalize: function() {
			  $(window).load(function(){
				  $('.grid').masonry({
					  itemSelector: '.grid-item'
				  });
			  });
		  }
	  }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

	//finishing animation after mouseleave
	$(".icons").hover(function() {
			$(this).addClass("anim");
	}, function() {
		$(this).on('animationiteration webkitAnimationIteration', function() {
			$(this).removeClass("anim");
		});
	});

  //triggering reservation modal
  $('.modal-trigger').on('click', function(e) {
   $('body').addClass('off-canvas-open');
  });

  $('.off-canvas__close').on('click', function(e) {
    $('body').removeClass('off-canvas-open');
  });

  // Load Events
  $(document).ready(UTIL.loadEvents);
})(jQuery); // Fully reference jQuery after this point.
